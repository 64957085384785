// filters for product table on homepage 
//table#products tr {display:none} 

.strike {
  text-decoration: line-through;
  color: red;
}
table#products .strike {
  text-decoration: line-through;
  color: gray;
}


// table#products{
//   tr { display:none; }
//   &[data-filter-1="filter-size-king"] tr.filter-size-king { display: inherit; }
//   &[data-filter-1="filter-size-standard"] tr.filter-size-standard { display: inherit; }
//   &[data-filter-1="filter-size-california-king"] tr.filter-size-california-king { display: inherit; }
//   &[data-filter-1="filter-size-king"] tr.filter-size-king{ display: inherit; }
//   &[data-filter-1="filter-size-queen"] tr.filter-size-queen{ display: inherit; }
//   &[data-filter-1="filter-size-full"] tr.filter-size-full{ display: inherit; }
//   &[data-filter-1="filter-size-twin-xl"] tr.filter-size-twin-xl{ display: inherit; }
//   &[data-filter-1="filter-size-twin"] tr.filter-size-twin{ display: inherit; }
//   &[data-filter-2="filter-category-standard-mattresses"] tr.filter-category-standard-mattresses{ display: inherit; }
//   &[data-filter-2="filter-category-premium-mattress"] tr.filter-category-premium-mattress{ display: inherit; }
//   &[data-filter-3="filter-brand-puffy-mattress"] tr.filter-brand-puffy-mattress{ display: inherit; }
//   &[data-filter-3="filter-brand-nectar-sleep"] tr.filter-brand-nectar-sleep{ display: inherit; }
// }



