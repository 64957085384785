//@charset "utf-8";
//@import "../node_modules/bulma/bulma.sass"; 
//https://versions.bulma.io/0.7.0/documentation/overview/customize/

// 1. Import the initial variables
@import "../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../node_modules/bulma/sass/utilities/functions";

// 2. Set your own initial variables
$navbar-breakpoint: $tablet !default;
$navbar-background-color: whitesmoke;
$widescreen-enabled: false;

//$scheme-main: red;


// Update blue
//$blue: #72d0eb;
// Add pink and its invert
//$pink: #ffb3b3;
//$pink-invert: #fff;
// Add a serif family
//$family-serif: "Merriweather", "Georgia", serif;

// 3. Set the derived variables
// Use the new pink as the primary color
//$primary: $pink;
//$primary-invert: $pink-invert;
// Use the existing orange as the danger color
//$danger: $orange;
// Use the new serif family
//$family-primary: $family-serif;

// 4. Setup your Custom Colors
//$linkedin: #0077b5;
//$linkedin-invert: findColorInvert($linkedin);
//$twitter: #55acee;
//$twitter-invert: findColorInvert($twitter);
//$github: #333;
//$github-invert: findColorInvert($github);

// 5. Add new color variables to the color map.
@import "../../node_modules/bulma/sass/utilities/derived-variables";
// $addColors: (
//   "twitter":($twitter, $twitter-invert),
//   "linkedin": ($linkedin, $linkedin-invert),
//   "github": ($github, $github-invert)
// );
//$colors: map-merge($colors, $addColors);

// 6. Import the rest of Bulma
//@import "../bulma";
@import "../../node_modules/bulma/bulma.sass"; 



@import "heroImage.sass"; 

// Enhanced helpers
.is-radiusless-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.is-radiusless-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

// Customize how the card looks when used in a hero - homepage
.hero .card {border-radius: .5rem;}

// Strike out the old price
.strike {
  text-decoration: line-through;
  color: red;
}

// Update strike to be gray
@import "tableCustom.scss"; 

